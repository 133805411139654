import { ColorProps, useTheme, Box } from '@wegroup/design-system';
import { get } from 'lodash';
import React from 'react';
import { hasRichText } from '../../../../../../company-scan/utils/textUtils';
import { sanitize } from 'dompurify';
import '@mantine/tiptap/styles.css';

interface Props {
  value: string;
  color?: ColorProps['color'];
}

/**
 * This component should be used for displaying RichTextEditor values in the platform.
 * It is a copy of the RichTextEditor component from the Mantine package, but without
 * the editor functionality.
 *
 * It mainly contains the same HTML structure as the RichTextEditor component, but
 * with no functionality (so no input field).
 */
const RichText: React.FC<React.PropsWithChildren<Props>> = ({
  value,
  color,
}) => {
  const { colors } = useTheme();

  // If color provided was NOT HEX (was for example primary.400)
  // It will get the color from our theme colors :)
  const hexColorRx = new RegExp(/^#[0-9a-f]{3,6}$/i);
  if (
    typeof color === 'string' &&
    !hexColorRx.test(color) &&
    color?.toLowerCase() !== 'currentcolor'
  ) {
    color = get(colors, color);
  }

  if (!hasRichText(value)) return null;

  return (
    <Box className="mantine-RichTextEditor-root">
      <Box className="quill">
        <Box className="ql-snow">
          <Box
            className={`ql-editor`}
            sx={{
              'p, li, div, h1, h2, h3, h4, h5, h6': {
                color: `${color} !important`,
              },
              'p:empty::before': {
                content: '"\\00a0"', // This will create a whitespace for empty <p> tags
              },
              h1: {
                fontSize: '22px',
                fontWeight: 'bold',
              },
              h2: {
                fontSize: '18px',
                fontWeight: 'bold',
              },
              h3: {
                fontSize: '16px',
                fontWeight: 'bold',
              },
              h4: {
                fontSize: '14px',
                fontWeight: 'bold',
              },
              h5: {
                fontSize: '12px',
                fontWeight: 'bold',
              },
              h6: {
                fontSize: '10px',
                fontWeight: 'bold',
              },
              background: 'transparent',
              border: 'none',
            }}
            dangerouslySetInnerHTML={{
              __html: sanitize(value, { ADD_ATTR: ['target'] }),
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default RichText;
