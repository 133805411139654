const Radio = {
  defaultProps: {
    colorScheme: 'primary',
  },
  variants: {
    atTop: {
      control: {
        alignSelf: 'start',
        mt: '0.5',
      },
    },
  },
};

export default Radio;
